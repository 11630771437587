<template>
  <div class="policemen_warp">
    <div class="main_top">
      <div style="display: flex">
        <el-select
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          style="width: 300px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
            :disabled="disabled"
          ></el-option>
        </el-select>
        <el-input
          v-model="selectTxt"
          placeholder="请输入听证员信息搜索"
          style="width: 300px; margin-left: 10px; margin-right: 10px"
        ></el-input>
        <!-- <el-select
          v-model="specialtyid"
          multiple
          filterable
          placeholder="请选择专业领域"
          style="width: 300px"
        >
          <el-option
            v-for="item in allSpecialty"
            :key="item.id"
            :value="item.id"
            :label="item.professional"
          ></el-option>
        </el-select> -->
        <el-button
          slot="reference"
          @click="departSelect"
          class="style_button style_button_Sel"

          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <div style="display: flex">
        <div>
          <!-- <el-button class="style_button" type="text" @click="handleAdd()"
            >添加</el-button
          > -->
          <el-button
            class="style_button"
            style="background: white; color: #044bfe; border: 1px solid #044bfe"
            type="text"
            @click="handleExport()"
            >导出</el-button
          >
          <el-dialog
            :visible.sync="dialogVisible"
            @close="closeDialog"
            :close-on-click-modal="false"
          >
            <div slot="title" class="dialog_title">
              <span>满意度</span>
            </div>
            <div >
              <div class="label">
              <el-date-picker
                v-model="value_time1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 280px"
              >
              </el-date-picker>
              <el-input
                v-model="selectTxt1"
                placeholder="请输入听证名称搜索"
                style="width: 250px; margin-left: 10px"
                class="select-actName"
              ></el-input>

              <el-button
                slot="reference"
                class="style_button style_button_Sel"
                icon="el-icon-search"
                @click="activitySelect"
                >搜索</el-button
              >
              </div>
              <div class="hearContent">
                <el-table
                  border
                  :data="hringlist"
                  style="width: 100%"
                  height="300"
                >
                  <el-table-column prop="hringdate" label="听证日期" width="180">
                  </el-table-column>
                  <el-table-column prop="hringname" label="听证名称">
                  </el-table-column>
                  <el-table-column prop="evaluation" label="满意度评价">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-dialog>
          <el-dialog
            :visible.sync="dialogFormVisible"
            @close="closeDialog"
            :close-on-click-modal="false"
          >
            <div slot="title" class="dialog_title">
              <img src="../../assets/zhcpIcon/add_icon.png" alt="" />
              <span>{{ titleName }}</span>
            </div>
            <el-form :model="form">
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>听证员姓名
                </div>
                <el-input v-model="form.username" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>听证员性别
                </div>
                <el-select v-model="form.sex" placeholder="请选择">
                  <el-option
                    v-for="item in allSexy"
                    :key="item.id"
                    :value="item.id"
                    :label="item.gender"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>专业领域
                </div>
                <el-select
                  v-model="form.professional"
                  placeholder="请选择专业领域"
                >
                  <el-option
                    v-for="item in allSpecialty"
                    :key="item.id"
                    :value="item.professional"
                    :label="item.professional"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>所属检察院
                </div>
                <el-select
                  v-model="form.procuratorateid"
                  placeholder="请选择检察院"
                >
                  <el-option
                    v-for="item in proList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.procuratoratename"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>手机号码
                </div>
                <el-input v-model="form.phone" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>工作单位
                </div>
                <el-input
                  v-model="form.workunits"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>参与率</div>
                <el-input
                  v-model="form.participate"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label">
                  <span>*</span>满意度均值
                </div>
                <el-input
                  v-model="form.evaluation"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label-width="formLabelWidth">
                <div class="form_label" slot="label"><span>*</span>备注</div>
                <el-input v-model="form.remark" autocomplete="off"></el-input>
              </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleClick()">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      tooltip-effect="dark"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column label="ID" width="50" type="index">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template> -->
      </el-table-column>
      <el-table-column label="听证员姓名">
        <template slot-scope="scope">
          <p>{{ scope.row.username }}</p>
        </template>
      </el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          <p>{{ scope.row.sex == 0 ? "女" : "男" }}</p>
        </template>
      </el-table-column>
      <el-table-column label="专业领域">
        <template slot-scope="scope">
          <p>{{ scope.row.professional }}</p>
        </template>
      </el-table-column>
      <el-table-column label="手机号码">
        <template slot-scope="scope">
          <p>{{ scope.row.phone }}</p>
        </template>
      </el-table-column>
      <el-table-column label="所属检察院">
        <template slot-scope="scope">
          <p>{{ scope.row.procuratorate.procuratoratename }}</p>
        </template>
      </el-table-column>
      <el-table-column label="参与率">
        <template slot-scope="scope">
          <p>{{ scope.row.participate }}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" class="operate" width="400">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleDetail(scope.row.id)"
            class="editColor"
            >满意度</el-button
          >
          <el-button
            size="mini"
            @click="handleEdit(scope.row.id)"
            class="editColor"
            >编辑</el-button
          >
          <el-button
            size="mini"
            @click="handleStop(scope.row.id, scope.row.state)"
            :style="{
              background: scope.row.state == 1 ? '#FF4E4E' : '#2FC25B',
            }"
            >{{ scope.row.state == 1 ? "停用" : "启动" }}</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleRemove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import {
  getAllUserInfo,
  getUserInfoById,
  updateUserInfo,
  deleteUserInfo,
  addUserInfo,
  selectByUserid
} from "@/api/user";
import { getProInfoList } from "@/api/system";
import { getAllSpecialty } from "@/api/system";
import { downloadFile } from "@/util/formtime";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      options: [],
      perList: [],
      hringlist: [],
      value: [],
      list: [],
      loading: false,
      disabled: false,
      states: [],
      dialogFormVisible: false,
      dialogVisible: false,
      form: {},
      info: {},
      allSexy: [
        { id: 1, gender: "男" },
        { id: 0, gender: "女" },
      ],
      formLabelWidth: "95px",
      tableData: [], //干警列表
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      multipleSelection: [],
      allSpecialty: [],
      specialtyid: "",
      proList: [],
      HearList: [],
      selectTxt: "", //搜索当前项
      selectTxt1: "", //搜索当前项
      procuratorateid: null,
      selecttype: 1,
      titleName: "",
      value_time: [],
      value_time1: [],
    };
  },
  mounted() {
    this.list = this.states.map((item) => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    this.typeid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).types.id;
   if( this.typeid == 6){
      this.procuratorateid = -1;
    }else{
      this.procuratorateid = JSON.parse(window.localStorage.getItem("tltz_userinfo")).procuratorate.id;
      this.disabled = true
    }
    // console.log(this.perList);
    this._getAllUserInfo();
    this._getAllSpecialty();
    this.getList();
  },
  methods: {
    //获取列表
    getList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: '全部'
        })
      });

    },
    //获取列表
    _getAllUserInfo() {
      const info = {
        page: this.page,
        pageSize: this.pageSize,
        procuratorateid: this.procuratorateid,
        selecttype: this.selecttype,
        conditions: this.selectTxt
      };
      getAllUserInfo(info).then(async (data) => {
        if (data.data == null) {
          this.page = 1;
          this.tableData = [];
          Message.error(data.msg);
          return;
        } else {
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
        }
      });
    },
    //获取全部专业
    _getAllSpecialty() {
      getAllSpecialty().then((data) => {
        this.allSpecialty = data.data.list;
      });
    },
    //添加
    // handleAdd() {
    //   let res = this.perList.filter((val) => val == 8);
    //   console.log(res)
    //   if(!res.length){
    //     Message.error('您没有这个权限');

    //   }else{
    //   this.dialogFormVisible = true;
    //   this.form = {};
    //   this.currentId = "";
    //   this.titleName = "新增听证员信息";
    //   }
    // },
    //修改
    handleEdit(value) {
      let res = this.perList.filter((val) => val == 9);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.dialogFormVisible = true;
      this.currentId = value;
      this.titleName = "编辑听证员信息";
      getUserInfoById(value).then((data) => {
        data.data.procuratorateid = data.data.procuratorate.id;
        this.form = data.data;

      });
      }
    },
    //修改
    handleDetail(value) {
      // let res = this.perList.filter((val) => val == 9);
      // console.log(res)
      // if(!res.length){
      //   Message.error('您没有这个权限');

      // }else{
      this.dialogVisible = true;
      this.currentId = value;
      this.info.userid = value;
      this.selectByUserid();
      // this.titleName = "编辑听证员信息";
      // }
    },
    selectByUserid(){
      const info = this.info
      // console.log(info)
      selectByUserid(info).then((data) => {
          this.hringlist = data.data.list;

        });
    },
    //点击确定
    handleClick() {
      const id = this.currentId;
      if (!id) {
        addUserInfo(this.form).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogFormVisible = false;
          Message.success("提交成功！");
          await this._getAllUserInfo();
        });
      } else {
        updateUserInfo(this.form).then(async (data) => {
          if (data.code !== 200) {
            Message.error("操作失败！");
            return;
          }
          this.dialogFormVisible = false;
          await this._getAllUserInfo();
          Message.success("提交成功！");
        });
      }
    },
    handleStop(d, s) {
      let res = this.perList.filter((val) => val == 8);
      // console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      console.log(d, s);
      let is = s,
        id = d;
      if (is == 0) {
        is = 1;
      } else {
        is = 0;
      }
      this.$confirm("是否变更该听证员当前状态？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          const params = {
            id, 
            state: is
          }
          updateUserInfo(params).then(async (data) => {
            if (data.code !== 200) return Message.error("变更状态失败！");
            await this._getAllUserInfo();
            Message.success("变更状态成功！");
          });
        })
        .catch(() => {
          Message.info("已取消!");
        });
      }
    },
    //删除
    handleRemove(id) {
      let res = this.perList.filter((val) => val == 10);
      // console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      this.$confirm("是否删除听证员信息？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteUserInfo(id).then(async (data) => {
            if (data.code !== 200) return Message.error("删除失败");
            // console.log(data.code);
            await this._getAllUserInfo();
          });
          Message.success("删除成功！");
        })
        .catch(() => {
          Message.info("已取消删除!");
        });
      }
    },
    //搜索
    departSelect() {

      this._getAllUserInfo()
      // let name = this.selectTxt;
      // this.page = 1;
      // fuzzySearchExpert(name, this.page).then((data) => {
      //   console.log(data.data);
      //   if (data.data == null) {
      //     Message.warning("查询内容不存在！");
      //   } else {
      //     this.tableData = data.data.list;
      //     this.total = data.data.total;
      //     this.currentPage = data.data.pageNum;
      //   }
      // });
    },
    activitySelect() {
      // console.log(this.value_time1)
      if(this.value_time1 !== null){
        this.info.stdate = new Date(this.value_time1[0]).toLocaleDateString().replace(/\//g, '-');
        this.info.enddate = new Date(this.value_time1[1]).toLocaleDateString().replace(/\//g, '-');
      }else{
        this.info.stdate = "";
        this.info.enddate = "";
      }
      if(this.selectTxt1 !== ''){
        this.info.hringname = this.selectTxt1;
      }else{
        this.info.hringname = '';
      }
      this.selectByUserid();
    },
    //导出
    handleExport() {
      let res = this.perList.filter((val) => val == 11);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');

      }else{
      downloadFile(BASE_URL + `/user/exportExpert`);
      }
    },
    //表单
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //分页器
    handleCurrentChange(val) {
      this.page = val;
      // console.log(this.selectTxt);
      // if (this.selectTxt !== "") {
      //   this.departSelect();
      //   return;
      // }
      this._getAllUserInfo();
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
  },
  computed: {
    sexyOp() {
      return this.form.gender == "0" ? "男" : "女";
    },
  },
};
</script>
<style lang='less' >
.policemen_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 0 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .el-dialog {
    // margin: 300px auto !important;
    
    min-width: 700px !important;
  }

  .hearContent {
    margin-bottom: 30px;
    .hearTitle {
      font-size: 16px;
    }
  }
  .el-dialog__header {
    border-bottom: 1px solid #e9e6e6;
  }
  .el-dialog__footer {
    border-top: 1px solid #e9e6e6;
  }
  .dialog_title {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
    > span {
      font-size: 13px;
      font-weight: 400;
      color: #2d8cf0;
    }
  }
  .label {
    width: 650px !important;
  }
  .form_label {
    > span {
      color: red;
      margin-right: 5px;
    }
  }
  .el-form-item__label {
    text-align: left;
    .form_label {
      font-size: 13px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item .el-input__inner {
    width: 275px;
    height: 23px;
  }
  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
  }
  .style_button_del {
    background: #ff0012;
    margin-left: 13px;
  }
  .el-table td,
  .el-table th {
    text-align: center;
    padding: 8px 0;
  }
  .el-table {
    margin-top: 15px;
    .el-button {
      width: 80px;
      height: 36px;
      font-size: 14px;
      color: #ffffff;
    }
    .editColor {
      background: #3a8fea;
    }
  }
  .el-button--danger {
    background: #ff5200 !important;
  }
  .el-pagination {
    // position: absolute;
    // bottom: 34px;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
}
.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
.style_button_Sel {
  margin-left: 10px;
}
</style>